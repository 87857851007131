<template>
  <CrmFormLayout customClass="auditForm" title="审核报盘" v-if="showType === 1">
    <div class="view__form">
      <div class="view__group">
        <label class="view__label">大类</label>
        <div class="view__input">{{ DetailsData.categoryName }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">国家</label>
        <div class="view__input">{{ DetailsData.countryName }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">厂号</label>
        <div class="view__input">{{ DetailsData.plantNo }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">供 应 商</label>
        <div class="view__input">
          <el-tooltip
            effect="dark"
            :content="DetailsData.supplierName"
            placement="top-start"
            :disabled="DetailsData.supplierName && DetailsData.supplierName.length <= 27"
            class="tootip-over"
          >
            <div>{{ DetailsData.supplierName }}</div>
          </el-tooltip>
        </div>
      </div>

      <div class="view__group">
        <label class="view__label">Incoterm</label>
        <div class="view__input">{{ DetailsData.incotermName }}</div>
      </div>

      <div class="view__group">
        <label class="view__label">付款条件</label>
        <div class="view__input">{{ DetailsData.supplierPaymentRatio }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">交易币种</label>
        <div class="view__input">{{ DetailsData.tradeCurrencyName }}</div>
      </div>
      <div class="view__group">
        <label class="view__label">当日汇率</label>
        <div class="view__input">{{ DetailsData.exchangeRate }}</div>
      </div>
    </div>

    <!-- 报盘信息 -->
    <el-table :data="tableData" :span-method="objectSpanMethod" border class="view-table mb-20" v-if="status">
      <el-table-column align="center" prop="nameCn" label="中文品名" min-width="200" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="nameEn" label="英文品名" min-width="202" show-overflow-tooltip></el-table-column>
      <el-table-column
        align="center"
        prop="unitPrice"
        :label="'价格(' + DetailsData.tradeCurrencyName + '/吨)'"
        width="120"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column align="center" prop="unitPriceRmb" label="价格(元/吨)" width="109" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="containerQuantity" label="柜数" width="60" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="weightPerContainer" label="每柜吨数" width="90" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="shippingDate" label="船期" width="90" show-overflow-tooltip></el-table-column>
      <el-table-column algin="center" label="标签" width="90" show-overflow-tooltip prop="labelUrl" class-name="is-center">
        <template #default="scope">
          <!-- 单品 -->
          <template v-if="scope.row.productType === 1">
            <div v-if="scope.row.labelUrl && scope.row.labelUrl !== ''" @click="checkClick(scope.row, scope.$index, 1)" class="record__link">查看</div>
            <div v-else>-</div>
          </template>
          <!-- 件套 -->
          <template v-else>
            <div @click="checkDetailClick(scope.row)" class="record__link">查看明细</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column algin="center" label="规格说明书" width="90" show-overflow-tooltip class-name="is-center">
        <template #default="scope">
          <!-- 单品 -->
          <template v-if="scope.row.productType === 1">
            <template
              v-if="scope.row.specificationAttachmentDtos && scope.row.specificationAttachmentDtos !== '-' && scope.row.specificationAttachmentDtos !== null"
            >
              <TableFileContent :readOnly="true" :fileUrlsArray="scope.row.specificationAttachmentDtos" key-name="name" key-url="url" key-id="id">
              </TableFileContent>
            </template>
            <template v-else> - </template>
          </template>
          <!-- 件套 -->
          <template v-else>
            <div @click="checkDetailClick(scope.row)" class="record__link">查看明细</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column algin="center" label="产品图片" width="90" show-overflow-tooltip class-name="is-center">
        <template #default="scope">
          <!-- 单品 -->
          <template v-if="scope.row.productType === 1">
            <template
              v-if="scope.row.productPictureAttachmentDtos && scope.row.productPictureAttachmentDtos !== '-' && scope.row.productPictureAttachmentDtos !== null"
            >
              <TableFileContent :readOnly="true" :fileUrlsArray="scope.row.productPictureAttachmentDtos" key-name="name" key-url="url" key-id="id">
              </TableFileContent>
            </template>
            <template v-else> - </template>
          </template>
          <!-- 件套 -->
          <template v-else>
            <div @click="checkDetailClick(scope.row)" class="record__link">查看明细</div>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <WoollyDetail class="mb-20" v-for="(item, index) in DetailsData.partsDetailVos" :key="index" :data="item" :type="1"></WoollyDetail>

    <!-- 国外报盘记录 -->
    <div class="wolly-attch__warp">
      <div class="wolly-attch__title">国外报盘记录</div>
      <div class="wolly-attch__body">
        <template v-if="DetailsData.foreignOfferRecord.length > 0">
          <div class="record__link" v-for="item in DetailsData.foreignOfferRecord" :key="item.id" @click="$showFiles(item.name, item.url)">
            {{ item.name }}
          </div>
        </template>
        <div class="no-data" v-else>暂无数据</div>
      </div>
    </div>

    <div class="crm-button__group" style="padding: 20px">
      <button class="crm-form__button primary" @click="submit">通过</button>
      <button class="crm-form__button cancel" @click="cancel">不通过</button>
    </div>
  </CrmFormLayout>
  <CrmFormLayout :title="'查看明细'" @close="closeWoolyDetail" v-show="showType == 2">
    <WoollyDetail :data="woollyDetail" :type="3" @cancel="closeWoolyDetail"></WoollyDetail>
  </CrmFormLayout>
</template>

<script>
import { httpGet } from '@/api';
import WoollyDetail from '../WoollyDetail/WoollyDetail.vue';
import TableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { showFiles } from '@/utils/publicMethods';
export default {
  components: { WoollyDetail, TableFileContent },
  props: {
    data: {
      // 产品信息
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      DetailsData: {
        foreignOfferRecord: [],
        specifications: [],
      },
      status: false,
      tableData: [],
      colArrays: {},
      colNames: ['shippingDate', 'containerQuantity'],
      showType: 1,
      woollyDetail: {
        partsName: '',
        productPartsVos: [],
      },
    };
  },
  created() {
    this.getinitData(this.data.id);
  },
  methods: {
    async getinitData(id) {
      let res = await httpGet(`/malicrm/offer/noAuth/offerDetail/${id}`);
      if (res.code === 200) {
        this.DetailsData = res.result;
        this.tableData = Object.assign([], res.result.productVoList);
        console.log(this.tableData);
        this.setColArrays(this.colNames);
        this.status = true;
      } else {
        this.errorTip(res.message);
      }
    },
    submit() {
      this.ajax({
        type: 'POST',
        url: '/malicrm/offer/auditOffer',
        data: {
          id: this.DetailsData.id,
          version: this.DetailsData.version,
          status: 2,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.successTip('审核已通过');
            this.$emit('close', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    cancel() {
      this.ajax({
        type: 'POST',
        url: '/malicrm/offer/auditOffer',
        data: {
          id: this.DetailsData.id,
          version: this.DetailsData.version,
          status: 3,
        },
        loading: true,
        success: (res) => {
          if (res.code === 200) {
            this.errorTip('审核不通过');
            this.$emit('close', true);
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    // 查看
    checkClick(row, index, type) {
      // todo
      if (type === 1) {
        // 标签
        window.open(row.labelUrl, '_blank');
      } else if (type === 2) {
        // 规格说明书
        let { name, url } = row.specificationAttachmentDtos[0];
        showFiles(name, url);
      } else {
        // 产品图
        let { name, url } = row.productPictureAttachmentDtos[0];
        showFiles(name, url);
      }
    },
    // 获取明细信息
    async getDetailInfo(id, name) {
      // 获取名字
      this.woollyDetail.partsName = name;
      let res = await httpGet(`/malicrm/offer/noAuth/offerProductPartsDetail/${id}`);
      if (res.code === 200) {
        // 获取表格信息
        this.woollyDetail.productPartsVos = res.result;
      }
    },
    // 查看明细
    checkDetailClick(row) {
      console.log('row', row);
      this.getDetailInfo(row.id, row.name);
      this.showType = 2;
    },
    // 关闭查看明细
    closeWoolyDetail() {
      this.showType = 1;
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.tableData.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (v[prop] === this.tableData[i - 1][prop] && v.containerSeq === this.tableData[i - 1].containerSeq) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },
    objectSpanMethod({ column, rowIndex, columnIndex }) {
      if (columnIndex === 4 || columnIndex === 6) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (this.colNames && this.colNames.length > 0) {
          this.colNames.forEach((v) => {
            if (v === prop) {
              const _row = this.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }
        return params;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tootip-over {
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.view__form {
  padding: 24px 20px 4px;
  border: 1px solid #e3e4e6;
  background: #fff;
  overflow: hidden;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.wolly-attch__warp {
  width: 100%;
  border: 1px solid #e3e4e6;
  background: #fff;
}
.wolly-attch__title {
  font-size: 16px;
  color: #333;
  padding: 0 22px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px dashed #e3e4e6;
}
.wolly-attch__body {
  padding: 30px 22px;
  .no-data {
    text-align: center;
    color: #909399;
  }
  .record__link {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
