import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { httpPost } from '@/api';
import { erpConfirm, successMessage, errorMessage } from '@/utils/publicMethods';
export default (Instance: any) => {
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'offerNo',
      value: '',
      placeholder: '请输入报盘编号',
    },
    {
      type: 'signSelect',
      prop: 'category',
      value: '',
      options: 'goods_category_label',
      placeholder: '请选择大类',
    },
    {
      type: 'input',
      prop: 'country',
      value: '',
      placeholder: '请输入国家',
    },
    {
      type: 'input',
      prop: 'plantNo',
      value: '',
      placeholder: '请输入厂号',
    },
    {
      type: 'input',
      prop: 'supplierName',
      value: '',
      placeholder: '请输入供应商',
    },
    {
      type: 'input',
      prop: 'nameCn',
      value: '',
      placeholder: '请输入中文品名',
    },
    {
      type: 'input',
      prop: 'creator',
      value: '',
      placeholder: '请输入创建人',
    },
    {
      type: 'time',
      prop: ['createdTimeStart', 'createdTimeEnd'],
      value: [],
      placeholder: ['创建开始时间', '创建结束时间'],
    },
    {
      type: 'multiSelect',
      prop: 'status',
      value: [],
      options: [
        {
          label: '待审核',
          value: 1,
        },
        {
          label: '已通过',
          value: 2,
        },
        {
          label: '未通过',
          value: 3,
        },
      ],
      width: 180,
      placeholder: '请选择审核状态',
    },
    {
      type: 'number',
      prop: ['feedbackMinNum', 'feedbackMaxNum'],
      value: [],
      maxlength: 8,
      placeholder: ['请输入最小还盘数', '请输入最大还盘数'],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '报盘编号',
      component: defineComponent({
        template: `
          <div class="crm-cell-link">
          {{row.offerNo}}
          </div>
        `,
        props: ['row'],
        setup() {
          return {};
        },
      }),
      prop: 'offerNo',
      minWidth: 120,
    },
    {
      label: '大类',
      prop: 'categoryName',
      minWidth: 80,
    },
    {
      label: '国家',
      prop: 'countryName',
      minWidth: 80,
    },
    {
      label: '厂号',
      prop: 'plantNo',
      minWidth: 120,
    },
    {
      label: '供应商',
      prop: 'supplierName',
      minWidth: 120,
    },
    {
      label: '中文品名',
      prop: 'nameCn',
      minWidth: 120,
    },
    {
      label: '审核状态',
      prop: 'statusName',
      minWidth: 110,
    },
    {
      label: '报盘客户数',
      prop: 'offerNum',
      minWidth: 130,
    },
    {
      label: '还盘数',
      prop: 'feedbackNum',
      minWidth: 90,
    },
    {
      label: '已转商机数',
      prop: 'transformedBusinessCount',
      minWidth: 130,
    },
    {
      label: '创建人',
      prop: 'creator',
      minWidth: 100,
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 180,
    },
    {
      label: '操作',
      component: defineComponent({
        template: `
          <div class="crm-btn-groups">
            <el-button v-permission="['crm:offer:auditOffer']" v-if="row.status===1" type="warning" plain @click="auditClick(row)">审核</el-button>
            <el-button  v-permission="['crm:offer:auditOffer']"   v-if="row.status===2" type="warning" plain @click="oppositeAudit(row)">反审核</el-button>
            <el-button  v-permission="['crm:offer:auditOffer']" v-if="row.status===3" type="primary" plain @click="submit(row)">提交</el-button>          
          </div>
        `,
        props: ['row'],
        setup() {
          /** 审核 */
          const auditClick = (row) => {
            Instance.setupState.rowData.value = row;
            Instance.setupState.setFormType(4);
            Instance.setupState.showVisible();
          };
          // 反审核，09/27，目前还没控制权限
          const oppositeAudit = (row) => {
            erpConfirm('该报盘已审核成功，确认反审核吗？')
              .then(async () => {
                if (!row.transformedBusinessCount) {
                  let params = {
                    id: row.id,
                    status: row.status,
                    version: row.version,
                  };
                  const res = await httpPost('/malicrm/offer/feeBackAuditOffer', params);
                  if (res.code === 200) {
                    successMessage(res.message);
                    Instance.setupState.refreshTable();
                  } else {
                    errorMessage(res.message);
                  }
                } else {
                  errorMessage('该报盘已转化为商机，无法进行反审核');
                }
              })
              .catch(() => {
                return false;
              });
          };
          /** 提交 */
          const submit = (row) => {
            Instance.setupState.rowData.value = row;
            erpConfirm('确定该报盘已修改完毕，需要再次提交审核吗？')
              .then(async () => {
                const res = await httpPost('/malicrm/offer/auditOffer', {
                  id: row.id,
                  version: row.version,
                  status: 1,
                });
                if (res.code === 200) {
                  successMessage('已提交');
                  Instance.setupState.refreshTable(true);
                } else {
                  errorMessage(res.message);
                }
              })
              .catch(() => {
                return false;
              });
          };
          return { submit, auditClick, oppositeAudit };
        },
      }),
      prop: '',
      width: 130,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
